import React from "react"
import { graphql, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import Tabs from "@components/UI/Tabs"
import { UserAgreementDataType } from "@typings/requests/user-agreement"
import "./terms-and-conditions.scss"

const UserAgreement: React.FC<PageProps<UserAgreementDataType>> = props => {
  const { strapiUserAgreement: userAgreementData }: UserAgreementDataType =
    props.data
  const { header, agreements, title, description, metaImage } =
    userAgreementData
  return (
    <>
      <SEO
        title={title}
        description={description}
        image={metaImage.localFile.publicURL}
        pathname={props.location.pathname}
        lang="en"
      />
      <LayoutContainer
        className="terms"
        location={props.location}
        colorLangSwitcher="black"
      >
        <section className="container terms__header">
          <h1 className="terms__title container__item text text--h1">
            {header}
          </h1>
        </section>
        <section className="container terms__tabs">
          <div className="container__item">
            <Tabs tabs={agreements} />
          </div>
        </section>
      </LayoutContainer>
    </>
  )
}

export const query = graphql`
  {
    strapiUserAgreement {
      description
      title
      header
      metaImage {
        localFile {
          publicURL
        }
      }
      agreements {
        name
        text {
          data {
            text
          }
        }
        title
        id
      }
    }
  }
`

export default UserAgreement

import React from "react"
import "./buttons.scss"

interface ButtonPropsTypes {
  onClick: () => void
  label?: string
  className?: string
}

const Button: React.FC<ButtonPropsTypes> = ({
  onClick,
  label = "",
  className = "",
}) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {label}
    </button>
  )
}
export default Button

import React, { useState, useEffect } from "react"
import cn from "classnames"
import Button from "../Buttons/Button"
import { sanitize } from "@helpers/helpers"
import { getDefaultCountry } from "@services/api"
import { AgreementDataType } from "@typings/requests/user-agreement"
import "./tabs.scss"

interface TabsPropsTypes {
  tabs: AgreementDataType[]
}

const Tabs: React.FC<TabsPropsTypes> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState("")

  useEffect(() => {
    async function fetchCountryIso() {
      const countryIso: string = await getDefaultCountry({})
      const isTabsHasDefaultCountry = tabs.filter(
        item => item.name === countryIso
      )
      setActiveTab(isTabsHasDefaultCountry.length ? countryIso : "BY")
    }
    fetchCountryIso()
  }, [tabs])

  return (
    <section className="tabs">
      <nav className="tabs__nav tabs-nav">
        <ul className="tabs-nav__list list-unstyled">
          {tabs.map(item => (
            <li className="tabs-nav__item" key={item.name + item.id}>
              <Button
                className={cn(
                  "button button--small button--black button--rounded",
                  {
                    "button--active": activeTab === item.name,
                  }
                )}
                onClick={() => setActiveTab(item.name)}
                label={item.title}
              />
            </li>
          ))}
        </ul>
      </nav>
      <div className="tabs__body">
        {tabs.map(
          item =>
            item.name === activeTab && (
              <div
                key={"content_" + item.id}
                dangerouslySetInnerHTML={{
                  __html: sanitize(item.text.data.text),
                }}
              />
            )
        )}
      </div>
    </section>
  )
}
export default Tabs

Tabs.defaultProps = {
  tabs: [],
}
